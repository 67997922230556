<template>
<div>
	<el-dialog title="添加资产卡片" :visible.sync='dialogAddVisible' width="100%" height="100vh" :close-on-click-modal="false">
		<el-form  label-position="top"   ref="form" :model="form" prop=""   :rules="rules">
		 <el-tabs v-model="activeName">
		    <el-tab-pane label="基础设置" name="basic">
				<el-form-item label="卡片名称" prop="name">
					<el-input  v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="适用分类：" >
					<el-tree
					  :data="cateData"
					  show-checkbox
					  default-expand-all
					  node-key="id"
					  ref="tree"
					  highlight-current
					  @check-change="handleCheckChange"
					  :props="defaultProps">
					</el-tree>
				</el-form-item>
			</el-tab-pane>
		    <el-tab-pane label="卡片设计" name="card">	
				<div class="card-box" style="display: flex;">
					<div class="field-left">
						 <el-button class="" size="small" @click="appendGroup">添加分组</el-button>
						<p style="font-weight: bold;position: relative;margin-bottom: 20px;font-size: 14px;">
							<span>可选字段</span>
							<span style="color: #409EFF;position: absolute;right: 5px;" @click="addField">添加可选字段</span>
						</p>
						<div class="field-list">
							  <el-checkbox-group v-model="checkboxGroup" size="mini" >
								<el-row>
									<!-- <div v-for="group in groupData"> -->
									  <el-col :span="12" v-for="item in fieldData">
											<el-checkbox-button @change="handleField(item,$event)"  style="width: 120px;margin-top: 3px;"  :label="item.field_name">{{item.title}}</el-checkbox-button>
									  </el-col>
								  <!-- </div> -->
								</el-row>
							</el-checkbox-group>
						</div>
					</div>
						
					<el-row class="field-content">
						<div v-for="(group,index) in groupData"> 
							 <el-col :span="23" style="border-bottom:1px solid #E4E7ED;">  
								<p @click="clickFormField(group,index)" style="font-weight: bold;font-size: 16px;border-bottom: 3px solid #409EFF;display: inline-block;">{{group.group_name}}</p>
							</el-col> 
							<div v-for="item in group.data" @click="clickFormField(item)">
							  <el-col :span="8"  v-if="item.type !== 1 && item.type !== 4"> 
							
									<!-- 普通文本 -->
								  <el-form-item  :label="item.title" prop="" >
										<el-input  v-if="item.type < 1" style="width: 280px;" v-model="item.value" placeholder="" disabled></el-input>
										<el-select v-if="item.type == 9" v-model="item.value"  disabled placeholder="请选择"  style="width: 280px;">
										  <el-option label=""value="0">
										  </el-option>
										</el-select>
										
										<el-date-picker
											style="width: 280px;"
											v-if="item.type == 6"
											disabled
										    v-model="item.value"
										    type="date"
										    placeholder="选择日期">
										</el-date-picker>
								  </el-form-item> 
								 
							  </el-col>
							   <el-col :span="24" v-if="item.type == 1 || item.type == 4">
								   <!-- 多行文本 -->
								   <el-form-item v-if="item.type == 1" :label="item.title" prop="" >
								   	<el-input type="textarea" rows="5"  v-model="item.value" placeholder="" disabled></el-input>
								   </el-form-item> 
								   
								   <el-form-item v-if="item.type == 4" :label="item.title" prop="">
											<el-upload
											disabled
											  action="https://jsonplaceholder.typicode.com/posts/"
											  list-type="picture-card"
											 >
											  <i class="el-icon-plus"></i>
											</el-upload>
									</el-form-item> 						
								</el-col>
							  </div>
						</div>
					</el-row>
					<div class="field-right">
						<span style="font-weight: bold;">属性设置</span>
						<div v-if="preview.field_name">
							<el-form-item  label="标题" prop="">
								<el-input style="width: 220px;"  v-model="preview.title" placeholder=""  :disabled="preview.disabled"></el-input>
								<span style="color: #909399;font-size: 12px;" v-if="preview.default_status == 1">系统预置字段，不可修改</span>
							</el-form-item>
							
							<el-form-item label="必填">
								<el-switch
								  v-model="preview.required_status"
								  active-color="#13ce66"
								   :disabled="preview.disabled"
								 >
								</el-switch>
							</el-form-item>
							<el-form-item label="数据来源"  v-if="preview.type == 9 && !preview.disabled ">
								<el-button type="" size="small" @click="addTypeInput()"><i class="el-icon-plus"></i></el-button>
								<div  v-for="(rItem,rIndex) in preview.content" style="display: ruby;margin-bottom:10px;">
									<label>选项{{rIndex+1}}:</label> 
									<el-input v-model="rItem.name" placeholder=""  style="width:200px;display:block;margin-left:20px;"></el-input>
									<el-button size="small" icon="el-icon-delete" @click="delInput(rIndex)"></el-button> 
								</div>
							</el-form-item>
						</div>
						<div v-if="preview.isGroup">
							<el-form-item  label="标题" prop="" >
								<el-input style="width: 220px;"  v-model="groupData[preview.groupIndex].group_name" placeholder=""></el-input>
							</el-form-item>
						</div>
					</div>
				</div>
			</el-tab-pane>
		    <!-- <el-tab-pane label="高级设置" name="advanced">
				高级设置
			</el-tab-pane> -->
		  </el-tabs>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="dialogAddVisible = false">取 消</el-button>
			<el-button type="primary" @click="confirm()">确 定</el-button>
		</div>
	</el-dialog>
	
	<FieldVue ref='fieldDialog' :fieldData.sync="fieldData" v-if="Object.keys(fieldData).length > 0" ></FieldVue>
</div>
</template>

<script>
import FieldVue from './AddField.vue'
export default {
	inject: ["reload"],
	components:{
	    FieldVue
	},
    data() {
        return {
			dialogVisible:false,
			checkboxGroup:[],
			activeName:'card',
			dialogAddVisible:false,
			cateData:[],
			groupData:[], 
			fieldData:[],
			card_name:'',
			cate_id:[],
			
			preview:{		//右侧预览
				groupIndex:0,
				isGroup:false,
				field_name:'',
				type:0,
				field_keys:[],
				content:[
					{name:''},
				],
				required_status:0,
				disabled:false,
			},
			
			form:{
				id:0,
				name:'',
				cate_id:[],
			},
			defaultProps: {
				label: "name",
				children: 'children',
			},
			rules:{
				name: [{
					required: true,
					message: '请输入卡片名称',
					trigger: 'blur'
				}],
			}
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
		handleAdd(id=0,treeData){
			this.cateData =  treeData
			console.log(treeData)
			if (id < 1) {
				this.$api.get('AssetCard/add', null, res => {
					if (res.code == 200) {
						this.groupData =  res.data.group_data
						this.fieldData =  res.data.field_list
						this.checkboxGroup =  res.data.default_checked
						this.dialogAddVisible = true
					}
				})
			} else {
				this.handleEdit(id)
			}
		},
		
		
		//左侧添加字段
		addField(){
			this.$refs.fieldDialog.showDialog()
		},
		delInput(rIndex){ //删除动态文本框
		    this.preview.content.splice(rIndex,1)
		},
		
		//添加分组
		appendGroup(){
			console.log(this.groupData)
			let data  = {
				data:[],
				group_name:'分组标题'
			}
			
			this.groupData.push(data)
		},
		
		  //追击单选文本框
		addTypeInput(){  
		    let data = {
		        name:''
		    }			
			this.preview.content.push(data)
		
		},
		
		handleEdit(id){
			this.$api.get('AssetCard/edit',{id:id},res => {
				if (res.code == 200) {
					this.form = res.data.info
					this.fieldData =  res.data.field_data
					this.groupData =  res.data.group_data
					this.dialogAddVisible = true
					this.$nextTick(()=>{ 
						this.$refs.tree.setCheckedNodes(res.data.info.checked_keys)
						this.checkboxGroup = res.data.default_checked
					})
				}
			})
		},
		
		// 监听分类选择
		handleCheckChange(data, checked, indeterminate) {
			this.form.cate_id = this.$refs.tree.getCheckedKeys()
			 // console.log(this.$refs.tree.getCheckedNodes());
			 // console.log(this.$refs.tree.getCheckedKeys());
		    },
		  confirm(){
			   let url = 'AssetCard/add'
			  	if (this.form.id) {
			  		url = 'AssetCard/edit'
			  	}
				this.form.field_data =  this.groupData
			    this.$api.post(url, this.form, res => {
			  		if (res.code == 200) {
			  			this.dialogAddVisible = false
			  			this.$message.success(res.msg)
			  			this.reload();
			  			this.$refs["form"].resetFields();
			  
			  		} else {
			  			this.$message.error(res.msg)
			  		}
			  	})
		  },
		  // 选中表单元素
		  clickFormField(data,index=''){
			  if(!data.field_name){	//选中分组	
				this.preview = {
					groupIndex:index,
					isGroup:true
				}
			  }else{	//选中组元素
				  this.$nextTick(()=>{
					  this.preview =  data
					  if(data.content){
						   this.preview.content = data.content
					  }
					  if(data.default_status == 1) this.preview.disabled =  true
					  this.preview.required_status  = data.required_status == 1?true:false
						this.preview.isGroup =  false
				  })
			  }
		  },
		  
		  //监听当前选中CheckBox
		  handleField(data,event){
			 //  this.checkboxGroup.forEach(fItem=>{
				 if(event){	//选中
					 var status = true
					   this.groupData.forEach((item)=>{
					 	item.data.forEach((iItem,iKey)=>{
					 		if(data.field_name == iItem.field_name){
					 			status =  false
					 		}
					 	})
					   })
					 if(status){
						 data['group_key']  = this.groupData.length-1
					 	this.groupData[this.groupData.length-1]['data'].push(data)
					 }
				 }else{		//取消
					if(data.default_status == 1){
						 this.checkboxGroup.push(data.field_name)
						this.$message.error('系统预设字段不可取消！')
						return false
					}
					
					 this.groupData.forEach((item,index)=>{
						item.data.forEach((iItem,iKey)=>{
							if(data.field_name == iItem.field_name){
								this.groupData[index]['data'].splice(iKey,1)
							}
						})
					 })
					 this.checkboxGroup.forEach((item,index)=>{
						 if(data.field_name == item){
							 this.checkboxGroup.splice(index,1)
						 }
					 })
				 }
				 console.log( this.checkboxGroup)
			 // console.log(this.groupData)
			 // console.log(data)
			 // console.log(event)
			 // //  console.log(this.checkboxGroup)
		  },
    },
}
</script>
<style lang="scss">
	.field-content {
		.el-input__inner{
			background: white !important; 
		}
		.el-textarea__inner{
			background: white !important; 
		}
	}
	.field-left{
		.field-list{
			.el-checkbox-group{
				.el-checkbox-button__inner{
					width: 120px;
					text-align: left;
				}
			}
		}
	}
	
</style>
<style scoped lang="scss">
	.card-box{
		display: flex;
		.field-left{
			width: 15%;
			padding-right: 15px;
				.el-button{
					width: 120px;
					text-align: left;
					margin-top: 5px;
				}
			.el-checkbox-button__inner{
				width: 150px;
			}
		}
		.field-content{
			padding-left: 15px;
			width: 60%;
			height: 100vh;
			border-left: 1px solid #DCDFE6;
			border-right: 1px solid #DCDFE6;
			overflow: auto;
		}
		.field-right{
			padding-left: 15px;
			width: 10%;	
		}
		
		

	}
</style>