<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
	  资产卡片是资产所有信息字段的展示，可设置不同资产分类的资产展示不同的信息。
	 </div>  
	 <div style="margin-bottom:10px;">
	      <el-button size="small" type="" @click="handleAdd()">添加</el-button>
	  </div> 
	  <el-table
	     :data="tableData" 
	     border
		 :row-style="{height:'20px'}"
		  :cell-style="{padding: '0'}"
	     style="width:100%">
	     <el-table-column
	         prop="name"
	         label="卡片名称">
	     </el-table-column>
	      <el-table-column
			width="500"
	         prop="cate_name"
	         label="适用分类">
			 <template slot-scope="scope">
				 <p class="cate-name-nowrap">{{scope.row.cate_name}}</p>
			 </template>
	     </el-table-column>
	     <el-table-column 
	         prop="admin_name"
	         label="最后操作人">
	     </el-table-column>
	     <el-table-column
	         prop="update_time"
	         label="最后操作时间">
	     </el-table-column>
	     <el-table-column
	         label="操作">
	         <template slot-scope="scope">
	             <el-button  size="small" @click="handleAdd(scope.row.id)">编辑</el-button>
	             <el-button  size="small" v-if="scope.row.id > 1" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
	         </template>
	     </el-table-column>
	  </el-table>
	<AddVue ref="childDialog"></AddVue>
 </div>
</template>

<script>
import AddVue from './Add.vue'
export default {
	 inject:['reload'],
	components:{
	    AddVue,
	},
    data() {
        return {
			tableData:[],
			cateData:[]
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){   //获取企业数据
		    this.$api.post('AssetCard/index',null,res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
					 this.$api.get('AssetCategory/treeData', {type:1}, res => {
					 	if (res.code == 200) {
					 		this.cateData =  res.data.tree_list
					 	}
					 })
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		
		handleAdd(id=0){
			this.$refs.childDialog.handleAdd(id,this.cateData)
		},
		handleDel(row){
		      this.$alert("确定删除该卡片数据吗？", "删除提示", {
		         showConfirmButton: true,
		         showCancelButton: true,
		         callback: action => {
		             if (action == "confirm") {
		                 this.$api.delete("AssetCard/del", { "id": row.id }, res => {
		                     if (res.code == 200) {
		                         this.$message.success(res.msg);
		                         this.reload();
		                     }
		                 });
		             }
		             else if (action == "cancel") {
		                 this.reload();
		             }
		         }
		     });
		},

    },
}
</script>
<style scoped lang="scss">
	.cate-name-nowrap{
		width: 450px;
		overflow: hidden;
		text-overflow: ellipsis;
		height: auto;
		word-wrap: break-word;
		white-space: nowrap;
	}
</style>